import lang from '@cling/language'

export default {
  path: '/',
  components: {
    content: () => import('@/views/account/settings/SettingsBaseLayout.vue')
  },
  children: [
    // Misc - default settings page
    {
      path: 'company',
      name: 'settingsCompany',
      components: {
        content: () => import('@/views/account/settings/SettingsCompany.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: lang.t('router:settings.company')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'user',
      name: 'settingsUser',
      components: {
        content: () => import('@/views/account/settings/SettingsUser.vue')
      },
      meta: {
        navbar: {
          title: lang.t('router:settings.user')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'document',
      name: 'settingsDocument',
      components: {
        content: () => import('@/views/account/settings/SettingsProject.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: lang.t('router:settings.document')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'members',
      name: 'settingsMembers',
      components: {
        content: () => import('@/views/account/settings/SettingsMembers.vue')
      },
      meta: {
        permission: 'users:modify',
        navbar: {
          title: lang.t('router:settings.members')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'members/member/:id?',
      name: 'settingsMembersForm',
      components: {
        content: () =>
          import(
            '@/views/account/settings/components/SettingsMembersInvite.vue'
          )
      },
      props: {
        content: true
      },
      meta: {
        permission: 'users:modify',
        navbar: {
          title: lang.t('router:settings.membersForm')
        },
        body: {
          fullSize: true
        },
        edit: true
      }
    },
    {
      path: 'notifications',
      name: 'settingsNotifications',
      components: {
        content: () =>
          import('@/views/account/settings/SettingsNotifications.vue')
      },
      meta: {
        navbar: {
          title: lang.t('router:settings.notifications')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'integrations/zapier',
      name: 'settingsZapier',
      components: {
        content: () =>
          import('@/views/account/settings/integrations/zapier/Index.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: 'Zapier'
        },
        body: {
          fullSize: true
        }
      }
    },
    ...[{ name: 'Pipedrive' }, { name: 'Salesforce' }, { name: 'Hubspot' }].map(
      ({ name }) => ({
        path: `integrations/${name.toLowerCase()}`,
        name: `settings${name}`,
        components: {
          // Dynamic import must start relative to the importing file
          // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#imports-must-start-with--or-
          content: () =>
            import(
              `.././views/account/settings/integrations/${name.toLowerCase()}/Index.vue`
            )
        },
        meta: {
          navbar: { title: name },
          body: { fullSize: true }
        }
      })
    ),
    {
      path: 'integrations/api',
      name: 'settingsApi',
      components: {
        content: () =>
          import('@/views/account/settings/integrations/api/Index.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: 'API'
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'billing',
      name: 'settingsBilling',
      components: {
        content: () => import('@/views/account/settings/SettingsBilling.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: lang.t('router:settings.billing')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'billing/cancel',
      name: 'settingsCancelBilling',
      components: {
        content: () => import('@/views/account/settings/SettingsCancelBilling')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: `${lang.t('_common:cancel')} ${lang
            .t('router:settings.billing')
            .toLowerCase()}`
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'language',
      name: 'settingsLanguage',
      components: {
        content: () => import('@/views/account/settings/SettingsLanguage.vue')
      },
      meta: {
        navbar: {
          title: lang.t('router:settings.language')
        },
        body: {
          fullSize: true
        }
      }
    },
    // WinProfile page
    {
      path: 'sms-name',
      name: 'settingsSms',
      components: {
        content: () => import('@/views/account/settings/SettingsSms.vue')
      },
      meta: {
        permission: 'company:modify',
        feature: 'winProfile',
        navbar: {
          title: lang.t('router:settings.sms')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'subdomain',
      name: 'settingsSubdomain',
      components: {
        content: () => import('@/views/account/settings/SettingsSubdomain.vue')
      },
      meta: {
        permission: 'company:modify',
        navbar: {
          title: lang.t('router:settings.subdomain')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'email-domain',
      name: 'settingsEmailDomain',
      components: {
        content: () =>
          import('@/views/account/settings/SettingsEmailDomain.vue')
      },
      meta: {
        navbar: {
          title: lang.t('router:settings.emailDomain')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'social-media',
      name: 'settingsWebsitesSocialMedia',
      components: {
        content: () =>
          import('@/views/account/settings/SettingsWebsitesSocialmedia.vue')
      },
      meta: {
        permission: 'company:modify',
        feature: 'winProfile',
        navbar: {
          title: lang.t('router:settings.websitesSocialMedia')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'certificates',
      name: 'settingsCertificates',
      components: {
        content: () =>
          import('@/views/account/settings/SettingsCertificates.vue')
      },
      meta: {
        permission: 'company:modify',
        feature: 'winProfile, regionSE',
        navbar: {
          title: lang.t('router:settings.certificates')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'presentation',
      name: 'settingsCompanyPresentation',
      components: {
        content: () =>
          import('@/views/account/settings/SettingsCompanyPresentation.vue')
      },
      meta: {
        permission: 'company:modify',
        feature: 'winProfile',
        navbar: {
          title: lang.t('router:settings.companyPresentation')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'photos',
      name: 'settingsTabPhotos',
      documentTitle: lang.t('_common:photo_plural', {
        postProcess: 'capitalize'
      }),
      components: {
        content: () => import('@/views/account/settings/SettingsTabPhotos.vue')
      },
      meta: {
        permission: 'company:modify',
        documentTitle: lang.t('router:settings.tabPhotos'),
        navbar: {
          type: 'default',
          title: lang.t('_common:setting_plural', {
            postProcess: 'capitalize'
          }),
          show: false
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      // Needs an extra identifier otherwise it will share the exact path with its parent
      path: 'photos/photo/:id?',
      name: 'settingsPhotosVideos',
      components: {
        content: () =>
          import('@/views/account/settings/WinProfile/SettingsPhotosVideos.vue')
      },
      meta: {
        level: 2,
        permission: 'company:modify',
        feature: 'winProfile',
        navbar: {
          title: lang.t('router:settings.photosVideos')
        },
        body: {
          fullSize: true
        }
      }
    },
    {
      path: 'fortnox',
      name: 'settingsFortnox',
      components: {
        content: () =>
          import('@/views/account/settings/integrations/fortnox/Index.vue')
      },
      meta: {
        permission: 'fortnox:export',
        navbar: {
          title: 'Fortnox'
        },
        body: {
          fullSize: true
        }
      }
    }
  ]
}

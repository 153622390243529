import { companyUser } from '@cling/api'
import config from '@cling/config'
import { viewContext } from '@cling/globalState'
import { handleError } from '@cling/services/error'
import features from '@cling/services/features'
import logger from '@cling/services/logger'
import permissions from '@cling/services/permissions'
import { brands } from '@cling/static'
import webStorage from '@cling/utils/webStorage'

import get from 'lodash/get'
import Vue from 'vue'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'

import { global } from '@/store/action-types'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

// Helper to know if a to route is restricted
function isRestricted(to) {
  return to.matched
    .slice()
    .reverse()
    .some(({ meta }) => meta.requiresAuth === true)
}

export function createRouter(routes, store) {
  router.addRoutes(routes)
  sync(store, router)
  store.$router = router

  // Handler for views with modal support
  router.beforeEach((to, from, next) => {
    const sizeMap = {
      project: 1024,
      document: 1200
    }
    if (
      from.name && // Parent component exists
      get(from, 'matched[0].components.default.name') === 'Account' && // Make sure Account is a root parent
      window.innerWidth >= sizeMap[to.name] && // Window has necessary width
      ['project', 'document'].includes(to.name) && // Eligible routes with modal support
      to.params.modal !== false && // Is not a crictial path
      !from.meta.fullscreen // Prevent getting stuck on fullscreen-pages when using native history back
    ) {
      const setModal = obj => store.dispatch('application/PUSH_MODAL', obj)
      setModal(to)
      return next(false)
    }

    return next()
  })

  router.beforeEach((to, from, next) => {
    const error = store.getters['application/error']

    if (error) {
      return next(false)
    }

    return next()
  })

  router.beforeEach((to, from, next) => {
    if (to.meta.extension === true) viewContext.value = 'extension'
    return next()
  })

  // Verify email with verifyEmailToken query
  // Link from email with authToken and verifyEmailToken used to verify that email belongs to companyUsers
  router.beforeEach(async (to, from, next) => {
    const { verifyEmailToken } = to.query
    if (!verifyEmailToken) return next()
    const query = Object.assign({}, to.query)
    delete query.verifyEmailToken
    try {
      await companyUser.verifyEmail({ token: verifyEmailToken })
      // Remove authToken from the original query so we do not try to use it again, as it will fail next time as well
    } catch (err) {
      handleError(err, { showMessage: false })
    }
    return next({ path: to.path, query })
  })

  // Auto login with authToken param
  router.beforeEach(async (to, from, next) => {
    const { authtoken: authToken } = to.query

    // If no authToken or not restricted page return next
    if (!authToken) {
      return next()
    }

    // We got an authToken, login user
    await store.dispatch(global.LOGIN_AUTH_TOKEN, { authToken, to })
    return next()
  })

  // Logout user if token and superuser are present
  router.beforeEach(async (to, from, next) => {
    const token = webStorage.getItem('token')
    const superuser = to.query.superuser

    if (token && superuser) {
      await store.dispatch(global.LOGOUT_USER)
      next({ name: 'Login', query: { superuser } })
    }

    return next()
  })

  // Hook to redirect user when logged out and navigating to a protected route
  router.beforeEach(async (to, from, next) => {
    const isUserLoaded = store.getters['application/isUserLoaded']
    const token = webStorage.getItem('token')

    if (!isRestricted(to)) {
      return next()
    }

    if (!token) {
      if (to.meta.extension === true) {
        if (to.name !== 'extensionMain') return next() // login / register extension views
        return store.dispatch(global.LOGOUT_USER, {
          next: { name: 'extensionLogin' }
        })
      }

      const queryString = Object.keys(to.query)
        .map(key => `${key}=${to.query[key]}`)
        .join('&')
      const redirect = `${to.path}${queryString ? `?${queryString}` : ''}`
      return store.dispatch(global.LOGOUT_USER, {
        next: { name: 'Login', query: { redirect } }
      })
    }

    if (!isUserLoaded) {
      await store.dispatch(global.LOAD_USER)
    }

    return next()
  })

  // Add corresponding navbar to child route if it's not specified
  router.beforeEach((to, from, next) => {
    // Find any current route or parent that has meta navbar
    const matchedRoute = to.matched
      .slice()
      .reverse()
      .find(record => record.meta.navbar !== undefined)
    if (matchedRoute) {
      to.meta.navbar = matchedRoute.meta.navbar
      return next()
    }
    return next()
  })

  // Update document title
  router.beforeEach((to, from, next) => {
    if (
      to.params &&
      to.params.id &&
      to.meta &&
      to.meta.navbar &&
      to.meta.navbar.edit
    ) {
      document.title = to.meta.title.edit
    } else if (to.meta && to.meta.documentTitle) {
      document.title = to.meta.documentTitle
    } else if (to.meta && to.meta.navbar && to.meta.navbar.title) {
      document.title = to.meta.navbar.title
    } else if (to.meta && to.meta.title) {
      document.title = to.meta.title
    } else {
      // Default title
      document.title = brands[config.brand].name
    }
    return next()
  })

  // Update document title
  router.beforeEach((to, from, next) => {
    // Reset the nav title
    store.dispatch(global.UPDATE_VIEW_SETTINGS, {
      view: 'nav',
      settings: {
        title: null
      }
    })
    return next()
  })

  // Check permissions before entering the route
  router.beforeEach((to, from, next) => {
    const { permission } = to.meta || {}
    const { path: fromPath, name: fromName } = from

    if (!permission) return next()

    const isPageLoad = fromPath === '/' && fromName === null

    // Check permissions
    const [scope, perm] = permission.split(':')

    if (!scope || !perm) {
      logger.warn('Permission or scope is missing for validation of route.')
      return next()
    }

    if (permissions.checkPermission(scope, perm)) {
      return next()
    }

    if (!isPageLoad) {
      return next(false)
    }

    // Redirect the user instead of showing a white page
    return next({ name: 'account' })
  })

  // Check feature access before entering the route
  // feature is optional on the route, if provided multiple features can be specified by a comma separated string
  router.beforeEach((to, from, next) => {
    const { feature } = to.meta || {}
    const { path: fromPath, name: fromName } = from

    if (!feature) return next()

    const isPageLoad = fromPath === '/' && fromName === null

    // Split feature on comma (,) and check that all features are valid
    const featureParts = feature.replace(' ', '').split(',')
    if (
      featureParts.every(currentFeature =>
        features.checkFeature(currentFeature)
      )
    ) {
      return next()
    }

    if (!isPageLoad) {
      return next(false)
    }

    // Redirect the user instead of showing a white page
    return next({ name: 'account' })
  })

  router.beforeEach((to, from, next) => {
    Vue.prototype.$modal.hideAll()

    // Hide vuex modals in App.vue on route change
    if (store.getters['application/modals'].length) {
      store.commit('application/SET_MODALS', [])
    }

    next()
  })

  // Store current path in localStorage
  router.afterEach(to => {
    webStorage.setItem('lastPath', to.path)
  })

  // Since dynamic import is used for almost all routes
  // There's a probability that a chunk will not be loaded correctly
  // This should prevent a blank page if an error occurs
  // https://segmentfault.com/a/1190000016382323
  router.onError(error => {
    const pattern = /Loading chunk (\d)+ failed/g
    const isChunkLoadFailed = error.message.match(pattern)
    const targetPath = router.history.pending.fullPath
    if (isChunkLoadFailed) {
      router.replace(targetPath)
      // ! NOTE - If errors still occur try using location.reload() instead
      // https://blog.csdn.net/Maximus_ckp/article/details/85079244
    }
  })

  return router
}

export default router

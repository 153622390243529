/* eslint-disable no-console */
import config from '@cling/config'
import lang from '@cling/language'
import { languages, regions } from '@cling/static'
import { isAfter, isValid, parseISO } from '@cling/utils/date'

import axios from 'axios'
import Dinero from 'dinero.js'
import cloneDeepWith from 'lodash/cloneDeepWith'
import uniqid from 'uniqid'

export * from './dom'

const { hasOwnProperty } = Object.prototype

export const truncate = (s, n) =>
  (s || '').substr(0, n - 1) + ((s || '').length > n ? '...' : '')
export const capitalize = s =>
  typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1)
export const stripHtml = s =>
  typeof s !== 'string' ? '' : s.replace(/<[^>]+>/g, ' ')

export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key)
}

export function getPropByPath(obj, path, strict) {
  let tempObj = obj
  path = path.replace(/\[(\w+)\]/g, '.$1')
  path = path.replace(/^\./, '')

  const keyArr = path.split('.')
  let i = 0
  for (let len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break
    const key = keyArr[i]
    if (key in tempObj) {
      tempObj = tempObj[key]
    } else {
      if (strict) {
        throw new Error('please transfer a valid prop path to form item!')
      }
      break
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : null
  }
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

/**
 * Takes any javascript instance or type and returns the type as a string.
 *  example:
 *   - getType({}) = 'object'
 *   - getType([]) = 'array'
 *   - getType(null) = 'null'
 *
 * @param {*} value Value to get type from
 * @returns {String}
 */
export function getType(value) {
  if (value === undefined) return 'undefined'
  if (value === null) return 'null'
  if (Array.isArray(value)) return 'array'
  if (typeof value === 'object' && !(value instanceof Date)) return 'object'
  if (Number.isNaN(value)) return 'nan'
  if (value === Infinity) return 'infinity'
  if (Number.isInteger(value) || isFloat(value)) return 'number'
  if (typeof value === 'string') return 'string'
  if (typeof value === 'boolean') return 'boolean'
  if (typeof value === 'function') return 'function'
  if (value instanceof Date && isValid(value)) return 'date'

  return undefined
}

/**
 * Get nested property from object or array
 * Example:
 *  - [0].name
 *  - array.[0].name
 *  - array.0.name
 *  - object.sub.name
 *
 * @param {Object|Array} obj Object or Array to extract value from
 * @param {String} path Path to property
 *
 * @returns {*}
 */
export function getPropertyFromPath(obj, path) {
  if (!['array', 'object'].includes(getType(obj)))
    throw Error('Arg.0 must be an array or object')
  if (typeof path !== 'string') throw Error('Arg.1, path must be a string')
  let keys = path.replace(/\[(.*)\]/gi, (match, p1) => `.${p1}`).split('.')
  // If we are using a path that starts with [0], we need to remove the first key
  // as our regex always puts an . in front of [0]
  if (keys[0] === '') {
    keys = keys.splice(1)
  }

  const property = keys.reduce((prop, name) => {
    if (!prop) return prop

    if (typeof prop[name] !== 'object') {
      return prop[name]
    }

    return prop[name]
  }, obj)
  return property
}

/**
 * Will resolve after given time
 * @param {Number} ms Time to wait
 * @returns {Promise<void>}
 */
export const wait = ms => new Promise(r => setTimeout(r, ms))

/**
 * Converts an object to an query string
 * Will convert filter key to an _where query
 * Example filter: { status: { new: true }, name: { 'fel name': false }}
 *
 * @param {object} query Object representing the query
 * @param {object} options
 * @param {string} options.prefix Will be put in front of each key
 * @returns {String} Querystring or empty string
 */
export const buildQueryString = (query = {}, options = {}) => {
  if (typeof query !== 'object') throw Error('Query must be an object!')
  if (typeof options !== 'object') throw Error('Options must be an object!')

  const opts = { prefix: '_', convertFilter: true, ...options }

  let queryString = '?'

  function buildFilter(filter) {
    let requestQuery = ''
    function builder(obj) {
      let string = ''
      Object.keys(obj).forEach((key, fi) => {
        if (['or', 'and'].includes(key)) {
          const inceptionQuery = builder(obj[key])
          if (inceptionQuery) {
            string += `${key}(${builder(obj[key])})`
          }
        } else {
          Object.keys(obj[key]).forEach((value, vi) => {
            // add separator between items but not on first
            let string2 = ''
            const isEquals = obj[key][value] === true
            const isNotEquals = obj[key][value] === false
            const contains = obj[key][value] === 'contains'

            if (key.includes('.')) {
              // This is a key for nested properties, needs to be wrapped in string
              key = `'${key}'`
            }

            if (isEquals) {
              string2 = `${key}=${value}`
            } else if (isNotEquals) {
              string2 = `${key}!=${value}`
            } else if (contains) {
              string2 = `${key}*=${value}`
            }
            if (string2) {
              const invalidSigns = ['(', ',']
              if (
                !invalidSigns.includes(string[string.length - 1]) &&
                string.length
              )
                string = `${string},`
              string += string2
            }
          })
        }
      })

      return string
    }
    requestQuery = `_where=and(${builder(filter)})`
    return requestQuery
  }

  Object.keys(query).forEach((key, i) => {
    if (typeof query[key] === 'undefined') return

    const prefix = opts.prefix ? opts.prefix : ''
    let value = query[key]
    if (typeof value === 'string') value = encodeURIComponent(value)
    let string = `${prefix}${key}=${value}`

    if (key === 'filter' && opts.convertFilter) {
      string = buildFilter(value)
    }

    queryString = `${queryString}${i ? '&' : ''}${string}`
  })

  return queryString.length > 1 ? queryString : ''
}

/**
 * Append a unique id as a key to an object,
 * Will by default not overwrite existing uniqueId
 * @param {object} object An object to add unique id to
 * @param {boolean} force Should old uniqueId be overwritten if exist?
 *
 * @returns {object} Object with unique id
 */
export function addUniqueId(object, force = false) {
  if (typeof object !== 'object' || Array.isArray(object)) {
    throw Error('Arg.0 must be an object')
  }
  return {
    ...object,
    _uniqueId: object._uniqueId && !force ? object._uniqueId : uniqid()
  }
}

export function addNestedUniqueIds(obj) {
  if (typeof obj !== 'object' || obj === null) return

  for (const key of Object.keys(obj)) {
    const value = obj[key]

    // Input is null, undefined, 123, 'abc'
    if (!value || typeof value !== 'object') {
      continue
    }

    // Input is {}
    if (!Array.isArray(value)) {
      addNestedUniqueIds(value)
      continue
    }

    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] !== 'object' || Array.isArray(value[i])) continue
      value[i] = addUniqueId(value[i])
    }
    addNestedUniqueIds(value)
  }
}

export function removeNestedUniqueIds(obj) {
  if (!obj) return

  for (const key of Object.keys(obj)) {
    const value = obj[key]

    if (key === '_uniqueId') {
      delete obj[key]
      continue
    }

    if (!value || typeof value !== 'object') continue

    if (!Array.isArray(value)) {
      removeNestedUniqueIds(value)
      continue
    }

    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] !== 'object' || Array.isArray(value[i])) continue
      removeNestedUniqueIds(value)
    }
  }
}

/**
 * Get a clone with removed properties nested
 * @param {Object|Array} collection The input data
 * @param {String[]} excludeKeys Keys to exclude deep from the input
 */
export function omitDeep(collection, excludeKeys) {
  function omitFn(value) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach(key => {
        delete value[key]
      })
    }
  }

  return cloneDeepWith(collection, omitFn)
}

/**
 * UI helper function
 * Throttle this promise to resolve no faster than the specified time:
 * Used to avoid jacky UI when spinners are visible
 *
 * @param {Number} time Miniumum duration
 * @param {Function|Promise} promise Promise function that you want to run
 */
export function takeAtleast(time, promise) {
  return new Promise((resolve, reject) => {
    const limiter = new Promise(res => {
      setTimeout(res, time)
    })
    return Promise.all([promise, limiter])
      .then(([response, _]) => resolve(response))
      .catch(err => reject(err))
  })
}

// Hack for iOS devices
// This allows when calling focus() on an input to also open the iOS keyboard
// https://stackoverflow.com/a/55425845
export function focusAndOpenKeyboard(el, timeout) {
  if (!timeout) {
    timeout = 60
  }
  if (el) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    const __tempEl__ = document.createElement('input')
    __tempEl__.style.position = 'absolute'
    __tempEl__.style.top = `${el.offsetTop + 7}px`
    __tempEl__.style.left = `${el.offsetLeft}px`
    __tempEl__.style.height = 0
    __tempEl__.style.opacity = 0
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__)
    __tempEl__.focus()

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(() => {
      el.focus()
      el.click()
      // Remove the temp element
      document.body.removeChild(__tempEl__)
    }, timeout)
  }
}

/**
 * Checks if a number is half
 * @param {Number} number The number to test
 * @return {Boolean}
 */
function isHalf(number) {
  return Math.abs(number) % 1 === 0.5
}

/**
 * Checks if a number is even
 * @param {Number} number The number to test
 * @return {Boolean}
 */
function isEven(number) {
  return number % 2 === 0
}

/**
 * Round price
 * Uses the same method as Dinero default: half to even rule (banker's rounding)
 * @param {Number} number The price to round
 * @returns {Number}
 */
export function roundPrice(number) {
  const rounded = Math.round(number)
  if (isHalf(number)) return isEven(rounded) ? rounded : rounded - 1
  return rounded
}

/**
 * Round price of all Number types (except 0) within an object and it's children
 * @param {Object} object
 */
export function roundPriceNested(object) {
  if (!object || typeof object !== 'object') return

  for (const key of Object.keys(object)) {
    const value = object[key]

    if (value && typeof value === 'number') object[key] = roundPrice(value)
    else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        roundPriceNested(object[key][i])
      }
    } else roundPriceNested(object[key])
  }
}

/**
 * Helper to calculate the price after any discount
 * If anything changes here make sure to change in both Vue/API as this function is used in both environments
 *
 * @param {Object} obj
 * @param {Number} obj.price
 * @param {String} obj.discountType
 * @param {Number} obj.discount
 * @param {Number} obj.quantity Optional
 *
 * @returns {Number} Total amount after any discount
 */
export function getPriceAfterDiscount({
  price,
  discountType,
  discount,
  quantity = 1
}) {
  if (typeof price === 'undefined') throw Error('Missing param price')
  if (typeof discountType === 'undefined')
    throw Error('Missing param discountType')
  if (typeof discount === 'undefined') throw Error('Missing param discount')
  if (typeof quantity === 'undefined') throw Error('Missing param quantity')

  if (!quantity) return 0

  let totalAmount = price * quantity

  if (discountType === 'fixed' && discount) totalAmount -= discount
  else if (discountType === 'percentage' && !!discount) {
    totalAmount *= (100 - discount / 100) / 100
  }

  return roundPrice(totalAmount)
}

/**
 * Helper to calculate totalAmount for articles
 * Will add property and the same format as input parameter
 * If anything changes here make sure to change in both Vue/API as this function is used in both environments
 *
 * @param {Object[]|Object} articles Array of article objects or a single article object
 *
 * @returns {Object[]|Object} Returns an array of input articles if input was an array, or object if input was a object, with added properties
 */
export function addArticleTotalAmount(articles) {
  if (typeof articles !== 'object' && !Array.isArray(articles)) {
    throw Error('Missing valid parameter articles')
  }

  let articleList = Array.isArray(articles) ? articles : [articles]

  articleList = articleList.map(article => {
    const { price, quantity, discountType, discount } = article
    return {
      ...article,
      totalAmount: getPriceAfterDiscount({
        price,
        quantity,
        discountType,
        discount
      })
    }
  })

  if (!Array.isArray(articles)) return articleList[0]
  return articleList
}

/**
 * Helper to generate a new article with all defaults data needed
 * @param {Object} object The article data
 * @returns {Object} A article object with all fields needed to represent a article
 */
export function createArticle(object) {
  if (typeof object !== 'object' || Array.isArray(object)) {
    throw Error('Arg.0 must be an object')
  }
  let article = {
    ArticleId: null,
    article_no: null,
    id: null,
    vat: null,
    isDeductable: false,
    isRut: false,
    greenRot15: false,
    greenRot20: false,
    greenRot50: false,
    name: '',
    description: '',
    price: 0,
    currency: 'SEK',
    priceType: 'fixed',
    quantity: 1,
    showPrice: true,
    unitType: 'unit',
    position: null,
    discountType: 'fixed',
    discount: null,
    totalAmount: 0,
    packageId: null,
    ...object
  }

  // Add uniqueId
  article = addUniqueId(article)

  article = addArticleTotalAmount(article)
  return article
}

/**
 * Check if article has any houseWork prop set
 * Note: any changes should be made in sync vue/api
 * @param {Object} art Article object
 * @returns {Boolean} Will return true if article has house work
 */
export const articleHasHouseWork = art =>
  ['isDeductable', 'isRut', 'greenRot15', 'greenRot20', 'greenRot50'].some(
    k => !!art[k]
  )

/**
 * Get what type of house work an article has, if any
 * Note: any changes should be made in sync vue/api
 * @param {Object} art Article object
 * @returns {Boolean} Will return house work type of the article if it exists
 */
export const articleHouseWorkType = art => {
  const { isDeductable, isRut, greenRot15, greenRot20, greenRot50 } = art || {}

  if (isDeductable) return 'rot'
  else if (isRut) return 'rut'
  else if (greenRot15) return 'greenRot15'
  else if (greenRot20) return 'greenRot20'
  else if (greenRot50) return 'greenRot50'

  return null
}

/**
 * Get translated/formatted article label
 * Note: any changes should be synced with vue/api
 * @param {String} unit raw unformatted article unit string
 * @param {Object} obj Optional
 * @param {Number} obj.count Optional count used for pluralization
 * @param {Boolean} obj.includeCount Optional if count should be prefixed in return value, defaults to false
 * @param {String} obj.lng Optional which language to use
 * @returns {String} returns translated/formatted article unit
 */
export const articleUnitLabel = (
  unit,
  { count = null, includeCount = false, lng = null, instance } = {}
) => {
  if (typeof unit !== 'string') return ''

  const translator = instance || lang

  const i18nOptions = {
    ...(typeof count === 'number' && { count }),
    ...(lng && { lng })
  }

  // Plain strings, provided by us can be translated
  if (!unit.startsWith('$')) {
    let key = `unitType.${unit}`
    if (!instance) key = `_common:${key}`
    return includeCount
      ? translator.tc(key, i18nOptions)
      : translator.t(key, i18nOptions)
  }

  // Could be extended for a more extensive resolver depending on language + count etc
  // in order to generate plural suffix
  // note: not needed until language is extended beyond 'sv', 'en'
  const needsPluralHandling = i18nOptions.count !== undefined && count !== 1

  const [s, p] = unit
    .replace('$', '') // removes only first '$'
    .split(':') // separate singular | plural versions

  const res = needsPluralHandling && p ? p : s

  return includeCount ? `${count} ${res}` : res
}

/**
 * Compares value a with b
 * Will return a negative number if a is greater than b.
 * Will return a 0 value if both a and b are equal or of different type.
 * Example:
 *  - sortCompareFunction(1, 3) = -1
 *  - sortCompareFunction(3, 1) = 1
 *  - sortCompareFunction(1, 1) = 0
 *  - sortCompareFunction('1', 2) = 0
 *
 * @param {String|Date|Number} a
 * @param {String|Date|Number} b
 */
export function sortCompareFunction(a, b) {
  let aType = getType(a)
  let bType = getType(b)

  let valueA = a
  let valueB = b

  if (valueA === null) return -1
  else if (valueB === null) return 1

  const validTypes = ['string', 'date', 'number']
  if (aType !== bType || !validTypes.includes(aType)) return 0

  if (aType !== 'date') {
    // If both are parsable numbers
    if (
      !Number.isNaN(parseInt(valueA, 10)) &&
      !Number.isNaN(parseInt(valueB, 10)) &&
      /^(?:\d|\.)*$/i.test(valueA) &&
      /^(?:\d|\.)*$/i.test(valueB)
    ) {
      valueA = parseInt(valueA, 10)
      valueB = parseInt(valueB, 10)
      aType = 'number'
      bType = 'number'
    }

    // Compare numbers
    if (aType === 'number') {
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    }
    // everything else should be a string date or plain string

    // Check if some of the values is plain string
    if (!isValid(parseISO(valueA)) || !isValid(parseISO(valueB))) {
      return valueA.localeCompare(valueB)
    }

    // We can only remain with a date string
    valueA = parseISO(valueA)
    valueB = parseISO(valueB)
  }

  if (isAfter(valueA, valueB)) return 1
  if (isAfter(valueB, valueA)) return -1
  return 0
}

/**
 * Returns a filtered array from filter supplied.
 * By default all statements must be valid for an object to be included
 * If changing the filterMode to "some", only one statement must be true
 * The following filter would ignore all statuses with value
 * new or archived.
 *  {
 *    status: {
 *      new: false,
 *      archived: false,
 *    }
 *  }
 * @param {Object[]} array An array of objects to filter
 * @param {Object} filter filter to apply
 * @param {Object} filterMode How the filter should be applied
 */
export function filterObjectArray(array, filter, filterMode = 'all') {
  if (getType(array) !== 'array') throw Error('Arg.0, must be an array')
  if (getType(filter) !== 'object') throw Error('Arg.1, must be an object')
  const filterModes = {
    all: 'every',
    some: 'some'
  }

  if (!filterModes[filterMode])
    throw Error(`${filterMode} is not a valid filter mode!`)

  if (Object.keys(filter).length === 0) return array

  return array.filter(object =>
    Object.keys(filter)[filterModes[filterMode]](key =>
      Object.keys(filter[key])[filterModes[filterMode]](value => {
        if (!filter[key] || !object[key]) {
          return false
        }

        if (filter[key][value] === true) {
          return object[key].toString() === value
        } else if (filter[key][value] === false) {
          return object[key].toString() !== value
        } else if (filter[key][value] === 'contains') {
          return true
        }

        return false
      })
    )
  )
}

/**
 * Filter object properties to return only specified allowed properties
 * @param {Object} obj Object to filter
 * @param {String[]} allowedProperties Optional array of strings used to filter properties
 * @returns {Object} Object filtered by allowedProperties
 */
export function filterAllowedProperties(obj, allowedProperties = []) {
  // If no props was assigned, return the original object
  if (
    !allowedProperties ||
    (getType(allowedProperties) === 'array' && !allowedProperties.length)
  ) {
    return obj
  }
  const filteredObj = Object.keys(obj)
    .filter(key => allowedProperties.includes(key))
    .reduce(
      (result, key) => ({
        ...result,
        [key]: obj[key]
      }),
      {}
    )
  return filteredObj
}
/**
 * Filter object properties to return only properties that are not forbidden
 * @param {Object} obj Object to filter
 * @param {String[]} forbiddenProperties Optional array of strings used to filter properties
 * @returns {Object} Object filtered by forbiddenProperties
 */
export function filterForbiddenProperties(obj, forbiddenProperties = []) {
  // If no props was assigned, return the original object
  if (
    !forbiddenProperties ||
    (getType(forbiddenProperties) === 'array' && !forbiddenProperties.length)
  ) {
    return obj
  }
  const filteredObj = Object.keys(obj)
    .filter(key => !forbiddenProperties.includes(key))
    .reduce(
      (result, key) => ({
        ...result,
        [key]: obj[key]
      }),
      {}
    )
  return filteredObj
}

/**
 * Get the permission title for a companyUser based on permissions
 * @param {Object} permissionObject The permission object
 * @returns {String} Permission title as string, 'admin', 'creator', 'member'
 */
export function getPermissionTitle(permissionObject = {}) {
  const {
    manageCompany,
    manageProjects,
    manageOwnProjects,
    showPrices,
    manageAtas,
    manageOwnAtas,
    manageUsers
  } = permissionObject

  // Admin
  if (
    manageCompany &&
    manageProjects &&
    manageOwnProjects &&
    showPrices &&
    manageAtas &&
    manageOwnAtas &&
    manageUsers
  ) {
    return 'admin'
  }
  // Creator
  if (manageOwnProjects && showPrices && manageAtas && manageOwnAtas) {
    return 'creator'
  }
  return 'member'
}

/**
 * Helper to get when first delivery is scheduled to be sent, and nr of reminders.
 * Used to show a correct status badge
 * @param {Object[]} deliveries Array with delivery objects
 * @returns {Object} result
 * @returns {String|null} result.scheduledFirstAt String timestamp when first delivery is sent or null
 * @returns {Number} result.remindersSent How many reminders that has been sent
 */
export function getScheduledDeliveryData(deliveries = []) {
  const result = {
    scheduledFirstAt: null,
    remindersSent: 0
  }

  // If any deliveries was found
  if (deliveries && deliveries.length) {
    // Is there any first scheduled delivery that is active?
    const scheduledFirstDelivery = deliveries.find(
      delivery => delivery.status === 'active' && delivery.type === 'first'
    )

    if (scheduledFirstDelivery) {
      result.scheduledFirstAt = scheduledFirstDelivery.sendAt
    } else {
      const successfulDeliveries = deliveries.filter(
        delivery =>
          delivery.status === 'successful' && delivery.type !== 'first'
      )

      if (successfulDeliveries.length > 0) {
        result.remindersSent = successfulDeliveries.length
      }
    }
  }
  return result
}

/**
 * Get translated event string
 * @param {Object} inputEvents Latest event object
 * @param {String} projectStatus The project status
 * @returns {String|null} Translated event string or null
 */
export function getLatestEventString(latestEvent) {
  if (!latestEvent) {
    return null
  }
  const { code, updatedAt } = latestEvent

  // Translate event string, most important first
  // Mail dropped
  let result = null
  if (code === 'mailDropped') {
    return lang.t(`event.${code}`)

    // Viewed
  } else if (code === 'viewed') {
    const eventString = lang.t(`event.${code}`)
    result = `${eventString} ${lang.formatDistanceToNow(updatedAt)}`
  }
  return result
}

/**
 * Count number of decimals of a number
 * @param {Number} value Numeric value, example 10000 or 1500.50
 * @returns {Number} No of non zero decimals, example 100.50 would return 1, 100.505 returns 3
 */
function countNoOfDecimals(value) {
  if (Math.floor(value) !== value)
    return value.toString().split('.')[1].length || 0
  return 0
}

/**
 * Function to format a price shared between UI and backend
 * Any changes here should also be updated in the other environment
 * @param {Number} value Numeric price
 * @param {Object} object
 * @param {String} object.currency Which currency that is used (SEK) ISO 4217
 * @param {String} object.locale Optional which locale that is used (sv-SE) BCP-47
 * @param {Number} object.decimals how many decimals to show
 * @param {Boolean} object.suffix If a suffix should be added (x kr)
 * @param {Boolean} object.showZero If empty price 0 should be visible or not
 * @param {Number} object.withVat Add vat to the price
 * @param {Boolean} object.hideZeroDecimals Optional if decimals is specified this would hide 1,00 -> 1 etc, defaults to false
 * @returns {String}
 */
export function priceFormatShared(
  valueParam,
  {
    currency,
    locale,
    decimals = 0,
    suffix = true,
    showZero = false,
    withVat = null,
    hideZeroDecimals = false
  } = {}
) {
  if (typeof currency === 'undefined' || !currency)
    throw new Error('Missing param currency')
  let value = valueParam
  if (typeof value === 'undefined') value = 0
  if (!value && !showZero) return ''
  value = Number(value) || 0 // Converts String inputs to numbers
  value = Math.round(value) // Dinero only accepts integers

  let price = Dinero({ amount: value, currency })
  if (locale) price = price.setLocale(locale)

  if (withVat) price = price.multiply(100 + withVat).divide(100)

  // Handle no of decimals
  let formatter = suffix ? '$0,0' : '0,0'
  let noOfDecimals = decimals
  if (hideZeroDecimals) {
    const nonZeroDecimals = countNoOfDecimals(price.getAmount() / 100)
    noOfDecimals = nonZeroDecimals
  }
  if (decimals && noOfDecimals)
    formatter = `${formatter}.${'0'.repeat(noOfDecimals)}`
  return price.toFormat(formatter) // Use Dinero to return format
}

/**
 * Vue wrapper for priceFormatShared that provides default values
 * If no currency or locale is explicitly provided in options the default is applied
 * @param {Number} value Numeric price
 * @param {Object} options Options according to priceFormatShared
 */
export function priceFormat(value, options) {
  // Default values to use if not explicitly provided in options parameter
  const { locale } = lang

  return priceFormatShared(value, {
    ...options,
    ...(!options.locale && { locale })
  })
}

/**
 * Helper to get the currency symbol ('kr') for a locale and currency
 * @param {String} locale which locale that is used (sv-SE) BCP-47
 * @param {String} currency which currency that is used (SEK) ISO 4217
 * @returns {String} Returns the currency symbol like 'kr'
 */
const currencySymbolCache = {} // Due to a LOT of calls to this func, lets cache results to increase performance
export function getCurrencySymbol(currency, locale = null) {
  if (typeof currency === 'undefined' || !currency)
    throw new Error('Missing param currency')
  const _locale = locale || lang.locale

  // Try to find in cache
  if (currencySymbolCache[`${currency}_${locale}`])
    return currencySymbolCache[`${currency}_${locale}`]

  const result = (0)
    .toLocaleString(_locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()

  // Add to cache
  currencySymbolCache[`${currency}_${locale}`] = result
  return result
}

/**
 * Get user deviceType
 * @returns {String} String matching 'ios', 'android' or 'unknown'
 */
export function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPod/i)
  ) {
    return 'ios'
  } else if (userAgent.match(/Android/i)) {
    return 'android'
  }
  return 'unknown'
}

/**
 * Check if the current device is mobile or tablet
 * Source: https://stackoverflow.com/a/11381730 and original source http://detectmobilebrowsers.com/
 * @returns {Boolean} Returns true if the device is mobile or tablet
 */
export function isMobileOrTablet() {
  const toCheck = navigator.userAgent || navigator.vendor || window.opera
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      toCheck
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      toCheck.substr(0, 4)
    )
  ) {
    return true
  }
  return false
}

// http://stackoverflow.com/a/21742107
export function getMobileOperatingSystem() {
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) return 'Windows Phone'

  if (/android/i.test(userAgent)) return 'Android'

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS'

  return 'unknown'
}

export function getDeviceTypeAndMode(view) {
  return {
    deviceType: isMobileOrTablet() ? 'mobile' : 'desktop',
    deviceMode: ['app', 'extension'].includes(view) ? view : null
  }
}

/**
 * Decide if user is on mac-like device, i.e. Mac, iPad, iPhone etc
 * @returns {Boolean}
 */
export function isMacLike() {
  return navigator && (navigator.platform || '').indexOf('Mac') !== -1
}

/**
 * Get the browser region
 * Uses navigator to access region and if found returns uppercase region
 * @returns {String|null} Uppercase region string (like SE) or null if not found
 */
export function getBrowserRegion() {
  // Helper to parse a browser language on format sv-SE and return the region part 'SE'
  function parseBrowserLanguage(browserLang) {
    if (!browserLang) return null
    const parts = browserLang.split('-')
    if (parts && parts.length > 1 && parts[parts.length - 1]) {
      return parts[parts.length - 1].toUpperCase()
    }
    return null
  }
  // Helper to verify if a possible region is valid
  const isValidRegion = candidate =>
    candidate && Object.keys(regions).includes(candidate)

  // Parsing the browser navigator
  if (navigator && navigator.languages && Array.isArray(navigator.languages)) {
    const candidates = navigator.languages
      .map(parseBrowserLanguage)
      .filter(isValidRegion)
    if (candidates && candidates.length) return candidates[0]
  } else if (navigator && navigator.language) {
    const candidate = parseBrowserLanguage(navigator.language)
    if (isValidRegion(candidate)) return candidate
  }

  return null
}

/**
 * Get the browser language as ISO 639
 * If no valid language is found it fallback to en
 * @returns {String} Returns a valid language like 'sv'
 */
export function getBrowserLanguage() {
  // Helper to parse a browser language on format sv-SE  and return the language part 'sv'
  function parseBrowserLanguage(browserLang) {
    if (!browserLang) return null
    const parts = browserLang.split('-')
    if (parts && parts.length && parts[0]) {
      return parts[0].toLowerCase()
    }
    return null
  }
  // Helper to verify if a possible language is valid
  const isValidLanguage = candidate =>
    candidate && languages.includes(candidate)
  // Parsing the browser navigator
  if (navigator && navigator.languages && Array.isArray(navigator.languages)) {
    const candidates = navigator.languages
      .map(parseBrowserLanguage)
      .filter(isValidLanguage)
    if (candidates && candidates.length) return candidates[0]
  } else if (navigator && navigator.language) {
    const candidate = parseBrowserLanguage(navigator.language)
    if (isValidLanguage(candidate)) return candidate
  }

  return 'en'
}

/**
 * Removes undefined keys from an object or array
 * @param {Object} object Object to remove undefined from
 *
 * @returns {Object} Filtered object
 */
export function removeUndefined(object) {
  const filteredObject = { ...object }

  Object.keys(object).forEach(key => {
    if (typeof object[key] === 'undefined') {
      delete filteredObject[key]
    }
  })

  return filteredObject
}

export function insertItem(array, value, index) {
  const newArray = array.slice()
  newArray.splice(typeof index === 'number' ? index : array.length, 0, value)
  return newArray
}

export function removeItem(array, index) {
  const newArray = array.slice()
  newArray.splice(index, 1)
  return newArray
}

export function updateObjectInArray(array, value, index) {
  return array.map((item, i) => (i !== index ? item : { ...item, ...value }))
}

export const immutable = arr => ({
  add: (value, index) => insertItem(arr, value, index),
  remove: index => removeItem(arr, index),
  set: (value, index) => updateObjectInArray(arr, value, index)
})

export const moveArrayValue = (arr, fromIndex, toIndex) => {
  const arr2 = [...arr]
  const element = arr2[fromIndex]
  arr2.splice(fromIndex, 1) // Remove
  arr2.splice(toIndex, 0, element) // Insert
  return arr2
}

/**
 * Format a phone number async
 * Used to show phone numbers on a nice format
 * @param {String} phone The phone number to format
 * @param {Object} obj Optional
 * @param {String} obj.defaultRegion Optional which default region in ISO-3166 to use
 * @returns {Promise<Object|null>} Resolves with an object or null, the object includes like example:
 *  {
 *     "national": "08-500 015 15",
 *     "international": "+46 8 500 015 15",
 *     "uri": "tel:+46850001515",
 *     "isPossible": true,
 *     "isValid": true,
 *     "type": "FIXED_LINE",
 *     "countryCallingCode": "46",
 *     "region": "SE"
 *   }
 */
export const formatPhone = async (phone, { defaultRegion } = {}) => {
  if (!phone) return null
  const { data } = await axios.post(
    `${config.api.baseUrl}/public/validate/phone`,
    { phone, defaultRegion }
  )
  return data
}

/**
 * Takes an array and returns an array with only unique elements.
 * @param {Array} arr Array to extract elements from
 * @returns {Array} array with only the unique elements
 */
export function getUniqueArrayElements(arr) {
  return Array.from(new Set(arr))
}

// Function to flatten a nested object
export const flattenObject = (obj, prefix = '') => {
  const result = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, flattenObject(value, prefixedKey))
    } else {
      result[prefixedKey] = value
    }
  })
  return result
}

/**
 * Helper to get a monthly cost based on interval, count and cost
 * @param {Object} obj
 * @param {String} obj.interval The interval, any of 'day', 'week', 'month' or 'year'
 * @param {Number} obj.intervalCount The no of intervals to create each cycle
 * @param {Number} obj.amount Cost for the cycle
 * @returns {Number} Returns the monthly cost. Etc month * 3 and 3000 would return 1000.
 */
export function getMonthlyCost({ interval, intervalCount, amount }) {
  if (typeof interval === 'undefined') throw new Error('Missing param interval')
  if (typeof intervalCount === 'undefined')
    throw new Error('Missing param intervalCount')
  if (typeof amount === 'undefined') throw new Error('Missing param amount')
  if (!['day', 'week', 'month', 'year'].includes(interval))
    throw new Error('Param interval is not valid')

  if (!Number.isInteger(amount) && !isFloat(amount))
    throw new Error('Param amount is not a number')

  let monthlyCost

  if (interval === 'day') {
    // 1 month = 30.4368499 days // TODO: Should we round to 30?
    // We need to calculate how much 30.4368499 days is over intervalCount
    // Example: 15 days for 1k, then: 1k * (30.4368499 / 15) = 1k * 2 = ~2029  per month
    monthlyCost = amount * (30.4368499 / intervalCount)
  } else if (interval === 'week') {
    // 1 month = 4.34812141 weeks // TODO: Should we round to 4 or 5?
    // We need to calculate how much 4.34812141 weeks is over intervalCount
    // Example: 4 weeks for 1k, then: 1k * (4.34812141 / 4) = 1k * 1 = ~1087 per month
    monthlyCost = amount * (4.34812141 / intervalCount)
  } else if (interval === 'month') monthlyCost = amount / intervalCount
  else if (interval === 'year') {
    // 12 month = 1 year
    monthlyCost = amount / (intervalCount * 12)
  }

  // We always floor to not over estimate any monthly costs
  monthlyCost = Math.floor(monthlyCost)

  return monthlyCost
}

/**
 * Copy a string to client clipboard
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
 * @param {String} string
 * @returns {Promise<Boolean>} Returns true if copied otherwise false
 */
export const copyStringToClipboard = async string => {
  if (!string) throw new Error('Missing param string to copy')
  if (typeof string !== 'string')
    throw new Error('Param string must be a string to copy')

  // Old execCommand due to old browser
  if (!navigator.clipboard) {
    console.log('old')
    const el = document.createElement('textarea')
    document.body.appendChild(el)
    el.value = string
    el.select()
    document.execCommand('copy', false)
    document.body.removeChild(el)
    return true
  }

  try {
    await navigator.clipboard.writeText(string)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

import { extensionApi } from '@cling/api'
import { getDefaultClientObject } from '@cling/models/document/migrate'
import { global } from '@cling/store/action-types'

// Post message to top Hubspot frame
// Valid actions: 'DONE', 'CANCEL'
const postWindowMessage = action =>
  window.parent.postMessage(JSON.stringify({ action }), '*')

export const postModalDone = () => postWindowMessage('DONE')
const postModalCancel = () => postWindowMessage('CANCEL')

// Add Hubspot Reference to document form structure
// Suggests document clients
const createServiceReference = async externalRef => {
  const _formData = { externalReferences: [externalRef] }

  const { data } = await extensionApi.suggestClients(
    externalRef.service,
    externalRef
  )

  if (Array.isArray(data) && data.length) {
    _formData.clients = data.map(c => ({
      ...getDefaultClientObject(),
      ...c
    }))
  }

  return _formData
}

// Intercepts LOAD_DOCUMENT2_FORM action
// and modifies input argument in order to
// supply correct reference to a Hubspot resource
const addHubspotDocReference = (action, state, { _formData }) => {
  if (!_formData) return
  if (action.payload?.id) return // If not a new document, do not add ref

  action.payload._formData = { ...action.payload._formData, ..._formData }
}

// Close modal if saving but not sending document
const onDocSave = action => {
  if (!action.payload?.doSend) postModalDone()
}
// Methods to run BEFORE respective action
const beforeModifiers = {
  [global.LOAD_DOCUMENT2_FORM]: addHubspotDocReference
}

// Methods to run AFTER respective action
const afterModifiers = {
  [global.SAVE_DYNDOC_FORM]: onDocSave,
  [global.SAVE_TEMPLATE_FORM]: postModalDone
}

const actionHandler = (modifiers, options) => (action, state) =>
  modifiers[action.type]?.(action, state, options)

function install(Vue, { router, store }) {
  // Install flag
  let _installed

  // Setup store subscription to handle iframe/modal actions
  let storeSubscription

  const setupHubspotContext = async ({ service, type, typeId } = {}) => {
    _installed = true
    // document?.documentElement.setAttribute('context', 'hubspot');
    store.dispatch(global.UPDATE_VIEW_SETTINGS, {
      view: 'app',
      settings: { isFullPageWindow: false }
    })

    // Locally stored Hubspot object reference and suggested clients
    // collected from route, on doc _formData structure
    // (Note: stored on setup since, since store subscription doesn't handle async calls)
    let _formData
    if (service && type && typeId) {
      _formData = await createServiceReference({ service, type, typeId })
    }

    if (!storeSubscription) {
      storeSubscription = store.subscribeAction({
        before: actionHandler(beforeModifiers, { _formData }),
        after: actionHandler(afterModifiers)
      })
    }
  }

  // Global router guard to:
  // Setup Hubspot context
  // Handle un-authenticated / logged in users
  router.beforeEach(async (to, from, next) => {
    if (to.query.view !== 'hubspotNative') {
      // Could still have 'hubspotNative' context if caught in earlier user auth guard
      // query param 'view' then be found outside of router
      if (to.name === 'Login' && to.query.redirect) {
        const view = new URLSearchParams(location.search).get('view')
        if (view === 'hubspotNative' && !_installed) {
          return next({
            name: 'AppUserLogin',
            params: { app: 'hubspot' },
            query: { ...to.query }
          })
        }
      }

      // If not in 'hubspotNative' context, continue
      return next()
    }

    // Redirect to auth if not installed in our app
    const isAuthenticated = store.getters['settings/isAuthenticatedCRM'](
      'hubspot',
      'native'
    )
    if (!isAuthenticated)
      return next({ name: 'AppAuth', params: { app: 'hubspot' } })

    if (!_installed) await setupHubspotContext(to.query)

    if (to.params.context === 'cancel') postModalCancel()

    return next()
  })
}

export default {
  install
}

<template>
  <div
    class="pointer-events-none sticky top-0 z-10 flex h-10 w-full translate-y-4"
    :class="{ 'translate-y-2': mq === 'sm' }"
  >
    <div
      class="pointer-events-auto mx-auto inline-flex items-center justify-center rounded-full bg-gray-300/50 shadow-xl backdrop-blur-md"
      :class="{ 'ml-2': mq === 'sm' }"
    >
      <div
        class="ease flex h-full items-center justify-center gap-1 overflow-hidden rounded-full border border-gray-100 bg-white px-1.5 text-gray-500 transition duration-500"
      >
        <!-- PAGE NAVIGATION -->
        <div class="mr-2 inline-flex items-center gap-2">
          <input
            class="w-9 rounded-full border border-solid border-gray-200 bg-gray-100 text-center leading-loose text-gray-500"
            type="number"
            :value="currentPage"
            @blur="navigateToPage"
            @keyup.enter="navigateToPage"
          />
          <div class="text-sm font-medium">/ {{ numPages }}</div>
        </div>

        <transition name="slide-fade">
          <div
            v-if="mq !== 'sm' || compactExpand"
            class="button-transitions flex items-center"
          >
            <div class="h-8 w-px bg-gray-700/10" />
            <!-- ZOOM -->
            <div class="inline-flex items-center">
              <button
                class="h-10 w-10 rounded-full text-gray-400"
                @click="updateZoom(scale - (scale > 0.2 ? 0.1 : 0))"
              >
                <CIcon type="zoomOut" size="20" />
              </button>
              <input
                :key="scale"
                class="w-12 rounded-md border border-solid border-gray-200 bg-gray-100 text-center leading-loose text-gray-500"
                type="text"
                :value="formattedZoom"
                @blur="onManualZoom"
                @keyup.enter="onManualZoom"
              />
              <button
                class="h-10 w-10 rounded-full text-gray-400"
                @click="updateZoom(scale + (scale < 2 ? 0.1 : 0))"
              >
                <CIcon type="zoomIn" size="20" />
              </button>
            </div>

            <div class="h-8 w-px bg-gray-700/10" />

            <button
              class="h-10 w-10 rounded-full text-gray-400"
              @click="updateZoom('page-width')"
            >
              <CIcon type="boxBox" size="20" />
            </button>

            <!-- DOWNLOAD -->
            <div class="h-8 w-px bg-gray-700/10" />
            <button
              class="h-10 w-10 rounded-full text-gray-400"
              @click="$emit('download')"
            >
              <CIcon type="download" size="20" />
            </button>
          </div>
        </transition>
      </div>

      <button
        v-if="mq === 'sm'"
        class="h-10 w-10 rounded-full text-gray-400"
        @click="compactExpand = !compactExpand"
      >
        <CIcon
          :class="{ 'rotate-180': compactExpand }"
          class="duration-500"
          type="chevron-right"
          size="20"
        />
      </button>
    </div>
  </div>
</template>

<script>
import windowSize from '@cling/mixins/windowSize'

export default {
  name: 'PDFToolbar',
  mixins: [windowSize],
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    numPages: {
      type: Number,
      default: 0
    },
    scale: {
      type: [Number, String],
      default: 'page-width'
    },
    autoZoom: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      compactExpand: false
    }
  },
  computed: {
    formattedZoom() {
      if (this.autoZoom) return 'Auto' // use only the english term for all document languages
      return Number.parseInt(this.scale * 100) + '%'
    }
  },
  methods: {
    updateZoom(v) {
      this.$emit('update:scale', v)
      this.$emit('update:auto-zoom', v === 'page-width')
    },
    onManualZoom(e) {
      let val = e?.target?.value || ''

      // do nothing if value remains 'Auto'
      if (val === 'Auto') return

      // remove trailing % if it exists
      if (val.endsWith('%')) val = val.slice(0, -1)

      const parsedVal = Number.parseFloat(val)

      // let scale 1 (100%) be default if no valid value is given
      let res = 1

      if (!Number.isNaN(parsedVal)) {
        res = parsedVal / 100
        if (res < 0) res = 0 // limit to 0% zoom
        if (res > 5) res = 5 // limit to 500% zoom
      }

      this.updateZoom(res)
    },
    navigateToPage(e) {
      let val = e?.target?.value || ''
      val = Number.parseInt(val)

      if (Number.isNaN(val)) val = this.currentPage
      if (val < 1) val = 1
      if (val > this.numPages) val = this.numPages

      // if current page has not change, reset input element manually
      if (val === this.currentPage && typeof e?.target?.value !== 'undefined')
        e.target.value = this.currentPage

      this.$emit('navigate-to-page', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.button-transitions {
  transition:
    max-width 0.5s ease,
    opacity 0.5s ease,
    visibility 0.5s ease;
  max-width: 300px;
  opacity: 1;
  visibility: visible;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition:
    max-width 0.5s,
    opacity 0.5s,
    visibility 0.5s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  max-width: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
</style>
